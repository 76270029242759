<template>
  <div class="hello">
    <div class="center">
      <div class="name">上海市市北公证处<br />存证查验平台</div>
      <!--通过点击进行index值的修改-->
      <input
        class="cztab"
        :class="index == 0 ? 'czqs' : 'czss'"
        type="button"
        value="证据文件验证"
        @click="_state(0)"
      />
      <input
        class="cztab"
        :class="index == 1 ? 'czqs' : 'czss'"
        type="button"
        value="证书编号/证据编号查询"
        @click="_state(1)"
      />
      <div class="upload" v-if="index == 0">
        <input
          type="file"
          ref="inputer"
          style="font-size:16px;"
          accept="*"
        />
      </div>
      <div class="upload" v-if="index == 1">
        <input
          type="search" 
          icon="icon-search"
          v-model="input"
          placeholder="请输入有效的证书编号或者证据编号"
          style="width:90%; height:30px; font-size:16px;"
        />
      </div>
      <div class="yz" @click="showInfo">查询验证</div>
    </div>
    <div class="wrap" v-show="show">
      <div class="wrap_bg" @click="show = false"></div>
      <div class="content_img">
        <div class="imageWrapper" ref="imageWrapper" v-if="terminal">
          <div class="bg">
            <img src="../assets/back-shibei.jpg" />
            <!-- 市北背景 -->
          </div>
          <div class="content">
            <div class="title">证书编号：{{ info.proofNo }}</div>
            <div class="desc">
              本证书由上海市市北公证处与崇法链联合签发，证明存证文件（电子数据）自存证时间起内容保持完整，未被篡改。
            </div>
            <div class="one" style="margin-top: 10px;">
              <span>存证哈希：</span><span>{{info.txhash}}</span>
            </div>
            <div class="one">
              <span>数字指纹：</span
              ><span style="width: 240px;">{{ info.fileMd5 }}</span>
            </div>
            <div
              class="content-content"
              style="padding-top: 20px;padding-bottom: 20px;"
            >
              <div class="one">
                <span>存证来源：</span><span>{{info.trusteeUserName}}</span>
              </div>
              <div class="one">
                <span>文件名称：</span><span>{{info.fileName}}</span>
              </div>
              <div class="one">
                <span>证据编号：</span><span>{{info.certificateNo}}</span>
              </div>
              <div class="one">
                <span>文件格式：</span><span>{{info.fileExtension}}</span>
              </div>
              <div class="one">
                <span>文件大小：</span><span>{{info.fileVarSize}} ({{info.fileSize}}Bytes)</span>
              </div>
              <div class="one">
                <span>存证时间：</span><span>{{info.createTime}}</span>
              </div>
            </div>
            <div class="desc">说明</div>
            <div class="desc">
              1.市北云存证平台是上海崇法数据科技有限公司基于区块链技术定制开发，联合司法鉴定机构、公证处、大数据中心、数字证书认证中心等权威机构共同搭建的区块链可信电子数据存证平台。
            </div>
            <div class="desc">
              2.电子数据存证平台存证技术由上海崇法数据司法鉴定所检测鉴定。
            </div>
          </div>
        </div>
        <div class="imageWrapper" ref="imageWrapper" v-else>
          <div class="bg">
            <img src="../assets/back-shibei.jpg" />
          </div>
          <div class="content">
            <div class="title">证书编号：{{ info.proofNo }}</div>
            <div class="desc">
              本证书由上海市市北公证处与崇法链联合签发，证明文件（电子数据）自申请时间起已经存在且内容保持完整，未被簒改。
            </div>
            <div class="one" style="margin-top: 10px;">
              <span>存证哈希：</span><span>{{ info.txhash }}</span>
            </div>
            <div class="one">
              <span>数字指纹：</span
              ><span style="width: 240px;">{{ info.fileMd5 }}</span>
            </div>
            <div
              class="content-content"
              style="padding-top: 20px;padding-bottom: 20px;"
            >
              <div class="one">
                <span>取证人：</span><span>{{ info.trusteeUserName }}</span>
              </div>
              <div class="one">
                <span>证据名称：</span><span>{{ info.fileName }}</span>
              </div>
              <div class="one">
                <span>证据编号：</span><span>{{ info.certificateNo }}</span>
              </div>
              <div class="one">
                <span>取证类型：</span><span>{{ info.fileTypeName }}</span>
              </div>
              <div class="one">
                <span>取证时间：</span><span>{{ info.createTime }}</span>
              </div>
              <div class="one">
                <span>取证地址：</span><span>{{ info.upAddress }}</span>
              </div>
              <div class="one">
                <span>证据大小：</span><span>{{ info.fileVarSize }} ({{info.fileSize}}Bytes)</span>
              </div>
              <div class="one">
                <span>证据格式：</span><span>{{ info.fileExtension }}</span>
              </div>
            </div>
            <div class="desc">说明</div>
            <div class="desc">
              市北云小程序是上海崇法数据科技有限公司基于区块链技术定制开发，联合权威机构共同搭建的区块链可信电子数据存证平台。
            </div>
            <div class="desc">
              权威机构包括司法鉴定机构、公证处、大数据中心、数字证书认证中心等。
            </div>
          </div>
        </div>
        <div v-if="info.fileOssLink === null" class="down yz" @click="down">下载证书</div>
        <div v-if="info.fileOssLink !== null" class="down yz" @click="file">下载文件</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import html2canvas from "html2canvas";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      index: 0,
      show: false,
      info: false,
      terminal: "",
      formData: new FormData(),
      input: "",
      imgUrl: "",
      imgs: {},
      imgLen: 0,
      txtVal: 0,
      desc: "",
    };
  },
  methods: {
    _state(index) {
      this.index = index;
    },
    loadFile() {
      let inputDOM = this.$refs.inputer;
      if(inputDOM.files.length == 0){
          alert("请选择需要验证的文件");
          return;
      }
      // 通过DOM取文件数据
      console.log(inputDOM.files);
      this.file = inputDOM.files;
      for (let i = 0; i < this.file.length; i++) {
        let size = Math.floor(this.file[i].size / 1024);
        if (size > 10 * 1024 * 1024) {
          alert("请选择10MB以内的文件！");
          return;
        }
        this.formData.append("file", this.file[i]);
      }
      this.formData = false;
      this.formData = new FormData();
      this.formData.append("file", this.file[0]);
      this.formData.getAll("file");
      console.log("this.formData", this.formData);
      let that = this;
      axios
        .post("https://shibeinotary.shchongfa.com/api/file/uploadFile3", this.formData)
        .then(function(response) {
          if (response.data.code == 200) {
            console.log(response.data.data);
            that.terminal = response.data.data.terminal;
            that.info = response.data.data;
            that.show = true;
          } else {
            alert(response.data.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    convertToImage(container, options = {}) {
      // 设置放大倍数
      const scale = window.devicePixelRatio;

      // 传入节点原始宽高
      const width = container.offsetWidth;
      const height = container.offsetHeight;

      // html2canvas配置项
      const ops = {
        scale,
        width,
        height,
        useCORS: true,
        allowTaint: false,
        ...options,
      };

      return html2canvas(container, ops).then((canvas) => {
        // 返回图片的二进制数据
        return canvas.toDataURL("image/png");
      });
    },
    down() {
      // this.convertToImage(this.$refs.imageWrapper))
      html2canvas(this.$refs.imageWrapper).then((canvas) => {
        let dataURL = canvas.toDataURL("image/png");
        this.imgUrl = dataURL;
        if (this.imgUrl !== "") {
          var alink = document.createElement("a");
          alink.href = this.imgUrl;
          alink.download = "证书.png"; //图片名
          alink.click();
        }
      });
    },
    file() {
      let that = this;
      window.open(that.info.fileOssLink);
    },
    showInfo() {
      if (this.index == 0) {
        this.loadFile();
      } else if (this.index == 1) {
        this.formData = new FormData();
        if(this.input.length < 17  || this.input.length > 32) {
          alert("请输入有效的证书编号或者证据编号");
          return;
        }
        else if(this.input.length == 32){
          this.formData.append("certificateNo", this.input);
        }
        else{
          this.formData.append("proofNo", this.input);
        }
        console.log("this.formData", this.formData);
        let that = this;
        axios
          .post("https://shibeinotary.shchongfa.com/api/file/uploadFile3", this.formData)
          .then(function(response) {
            if (response.data.code == 200) {
              console.log(response.data.data);
              that.terminal = response.data.data.terminal;
              that.info = response.data.data;
              that.show = true;
            } else {
              alert(response.data.msg);
            }
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.center {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
}
.name {
  font-size: 36px;
  color: #fff;
  font-weight: bold;
  margin-top: 60px;
  margin-bottom: 30px;
}
.cztab {
  width: 240px;
  height: 48px;
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 30px;
  border-left: solid 1px #ffffff;
  cursor: pointer;
}
.cztab:first-child {
  border-left: 0;
}
.czqs {
  background: #147ceb;
}
.czss {
  background: #041b50;
}
.yz {
  width: 200px;
  height: 44px;
  text-align: center;
  line-height: 44px;
  color: #fff;
  background: #147ceb;
  margin: 0 auto;
  border-radius: 22px;
  cursor: pointer;
}
.wrap_bg {
  position: absolute;
  width: 100%;
  height: 100%;
}
.down {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
.bottom {
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
}
.upload {
  width: 600px;
  padding-top: 50px;
  height: 80px;
  background: #fff;
  margin: 0 auto 60px;
  justify-content: center;
  align-items: center;
}
.upload input {
  width: 300px;
  height: 88px;
  font-size: 18px;
  outline: none;
}
.wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  min-width: 400px;
}
.content_img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 690px;
  background: #fff;
  min-width: 400px;
}
.imageWrapper {
  width: 375px;
  height:612px;
  margin: 0 auto;
  position: relative;
}
.bg {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.bg img {
  width: 100%;
}
.content {
  position: relative;
  width: 300px;
  margin: 0 auto;
  padding: 80px 0 80px;
}
.title {
  font-size: 12px;
}
.one {
  font-size: 12px;
  text-align: left;
  display: flex;
  margin-bottom: 3px;
}
.one span:nth-child(1) {
  flex-shrink: 0;
  width: 60px;
}
.one span:nth-child(2) {
  width: 240px;
  word-wrap: break-word;
  word-break: normal;
}
.margin-top {
  margin-top: 40px;
}
.desc {
  font-size: 11px;
  text-align: left;
}
</style>
<style>
html {
  background: url("../assets/page.png") no-repeat;
  background-size: cover;
  height: 100vh;
}
</style>
